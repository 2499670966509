import { PermitKeys } from '../permission-keys';
import { PermissionCheckReturn, PermissionError, PermissionErrorCodes, PermissionMap, WorkspacePermissions } from '../permission-models';
import { DiscussionPermissionMap } from '../../../../../shared/discussion-types';

export class DiscussionPermission {
    constructor(
        private userId: string,
        private permissions: PermissionMap,
    ) { }

    /** Workspace owners and admins are discussion admins */
    isAdmin(workspaceId: string): PermissionCheckReturn {
        const [error, workspacePermissions] = this.workspacePermissions(workspaceId);
        if (error) {
            return [error];
        }

        const isOwner = workspacePermissions?.workspace?.isOwner;
        const isAdmin = workspacePermissions?.workspace?.isAdmin;
        if (!isOwner && !isAdmin) {
            return [{ name: 'Invalid permissions', message: 'User needs to be an admin' }];
        }

        return [undefined, true];
    }

    /** Has manage discussin from role */
    isModerator(workspaceId: string): PermissionCheckReturn {
        return this.hasCustomRolePermit(workspaceId, 'discussion.manage');
    }

    /** Discussion permissions */

    /** Discussion participants can load it */
    load(discussion: { cid: string, participants: string[], _id: string }): PermissionCheckReturn {
        if (discussion?.participants.includes(this.userId)) {
            return [undefined, true];
        }

        return [{ name: '', message: 'No discussion found!' }];
    }

    /** Discussion participants can star discussions */
    star(discussion: { cid: string, participants: string[], _id: string }): PermissionCheckReturn {
        return this.load(discussion);
    }

    /** Discussion participants can load files */
    files(discussion: { cid: string, participants: string[], _id: string }): PermissionCheckReturn {
        return this.load(discussion);
    }

    /** Discussion participants can load messages */
    messages(discussion: { cid: string, participants: string[], _id: string }): PermissionCheckReturn {
        return this.load(discussion);
    }

    /** Discussion participants can mute discussion */
    mute(discussion: { cid: string, participants: string[], _id: string }): PermissionCheckReturn {
        return this.load(discussion);
    }

    /** Discussion moderators and admins can manage discussions */
    manage(workspaceId: string, discussion: { cid: string, permissions?: DiscussionPermissionMap, members?: any }): PermissionCheckReturn {
        const [adminError, admin] = this.isAdmin(workspaceId);

        if (admin) {
            return [undefined, true];
        }

        const member = discussion?.members?.find(member => member?.id === 'user_' + this.userId);

        const [manageError, manage] = this.hasDiscussionPermit(workspaceId, 'discussion.manage', member?.permissions);

        if (manage) {
            return [undefined, true];
        }

        return [{ name: 'Invalid permissions', message: 'User is not a discussion moderator' }];
    }

    leave(discussion: { cid: string; linked_activity?: string | null; linked_event?: string | null; uid?: string; discussionHasModerator?: boolean; managed?: boolean; }): PermissionCheckReturn {
        if (!discussion) {
            return [{ name: 'Invalid discussion', message: 'No discussion provided' }];
        }

        // No one can leave a locked discussion
        if (discussion.managed) {
            return [{ name: 'Discussion locked', message: 'Unable to leave.' }];
        }

        // Always allow user to leave a discussion with no moderator (or higher)
        if (!discussion.discussionHasModerator) {
            return [undefined, true];
        }

        // Always allow user to leave a discussion they created
        if (discussion?.uid === this.userId) {
            return [undefined, true];
        }

        if (discussion?.linked_activity || discussion?.linked_event) {
            const [error] = this.hasCustomRolePermit(discussion?.cid, 'discussion.leave');
            if (error) {
                return [error];
            }
        } else {
            let canLeave = false;
            for (const workspaceId in this.permissions) {
                const [error, hasPermit] = this.hasCustomRolePermit(workspaceId, 'discussion.leave');

                if (hasPermit) {
                    // Has custom role permit
                    canLeave = true;
                    break;
                }

                if (!error && !hasPermit) {
                    // No custom role
                    canLeave = true;
                    break;
                }
            }

            if (!canLeave) {
                return [{ name: 'Invalid permissions', message: 'User is not a discussion moderator' }];
            }
        }

        return [undefined, true];
    }

    read = {
        history: (discussion: { cid: string, participants: string[], _id: string, permissions?: DiscussionPermissionMap, members?: any, private?: boolean }): PermissionCheckReturn => {
            const member = discussion?.members?.find(member => member?.id === 'user_' + this.userId);
            const [canSeeHistoryError, canSeeHistory] = this.hasDiscussionPermit(discussion?.cid, 'discussion.read.history', member?.permissions);
            if (canSeeHistoryError && !discussion?.private) {
                return [canSeeHistoryError];
            }

            return this.load(discussion);
        }
    };

    /** Message permission */
    message = {
        /** Discussion participants can load single message */
        get: (message: { uid: string, discussion: string }, discussion: { cid: string, participants: string[], _id: string }): PermissionCheckReturn => {
            if (message?.discussion === discussion?._id && this.load(discussion)) {
                return [undefined, true];
            }

            return [{ name: '', message: 'No message found!' }];
        },
        /** Message sender can edit it */
        edit: (message: { uid?: string, discussion?: string }, discussion: { cid: string, permissions?: DiscussionPermissionMap, members?: any }): PermissionCheckReturn => {
            const member = discussion?.members?.find(member => member?.id === 'user_' + this.userId);
            const [canEditOwnError] = this.hasDiscussionPermit(discussion?.cid, 'discussion.message.remove.own', member?.permissions);
            if (canEditOwnError) {
                return [canEditOwnError];
            }

            if (message?.uid === this.userId) {
                return [undefined, true];
            }

            return [{ name: 'Permission denied', message: 'User cannot edit someone elses message' }];
        },
        /** Message sender can delete it */
        delete: (message: { uid: string }, discussion: { cid: string, permissions?: DiscussionPermissionMap, members?: any }): PermissionCheckReturn => {
            const member = discussion?.members?.find(member => member?.id === 'user_' + this.userId);
            const [canEditOthersError, canRemoveOthers] = this.hasDiscussionPermit(discussion?.cid, 'discussion.message.remove.others', member?.permissions);
            if (canRemoveOthers) {
                return [undefined, true];
            }

            const [canRemoveOwnError] = this.hasDiscussionPermit(discussion?.cid, 'discussion.message.remove.own', member?.permissions);
            if (canRemoveOwnError) {
                return [canRemoveOwnError];
            }

            if (message?.uid === this.userId) {
                return [undefined, true];
            }

            return [{ name: 'Permission denied', message: 'User cannot remove someone elses message' }];
        },
        /** Discussion participants can react to message */
        react: (message: { uid: string, discussion: string}, discussion: { cid: string, participants: string[], _id: string }): PermissionCheckReturn => {
            if (message?.discussion === discussion?._id && this.load(discussion)) {
                return [undefined, true];
            }

            return [{ name: '', message: 'No message found!' }];
        },
        /** Users not denied from sending messages can send */
        send: (discussion: { cid: string, private?: boolean, permissions?: DiscussionPermissionMap, members?: any, participants?: string[], closed?: boolean },
            recipient?: {
                recipientId: string,
                recipientWorkspaces: { [workspaceId: string]: { _id: string, settings: { isolatedTeams?: boolean } } },
                recipientTeams: { cid: string, members?: string[], public?: boolean }[]
            }
        ): PermissionCheckReturn => {
            if (!discussion && !recipient?.recipientId) {
                return [{ name: 'Permission denied', message: 'No discussion' }];
            }
            if (discussion?.closed) {
                return [{ name: 'Closed', message: 'Discussion has been closed.' }];
            }
            if (recipient?.recipientWorkspaces) {
                for (const workspaceId of Object.keys(recipient.recipientWorkspaces)) {
                    const [adminError, admin] = this.isAdmin(workspaceId);
                    if (admin) {
                        return [undefined, true];
                    }
                }
            }
            if (discussion?.private && recipient?.recipientId) {
                return this.start.private(recipient.recipientId, recipient.recipientWorkspaces, recipient.recipientTeams);
            }
            if (!discussion && recipient?.recipientId) {
                return this.start.private(recipient.recipientId, recipient.recipientWorkspaces, recipient.recipientTeams);
            }
            // Forms use a dummy session to send anonymous messages
            if (this.userId === '000000000000000000000001') {
                return [undefined, true];
            }
            const member = discussion?.members?.find(member => member?.id === 'user_' + this.userId);
            const [canAddMessageError] = this.hasDiscussionPermit(discussion?.cid, 'discussion.message.add', member?.permissions);
            if (canAddMessageError) {
                return [canAddMessageError];
            }

            return [undefined, true];
        },
        add: {
            /** Checks if user can add attachments to messages */
            attachment: (discussion: { cid: string, permissions?: DiscussionPermissionMap, members?: any }, workspaceId?: string): PermissionCheckReturn => {
                if (!discussion && !workspaceId) {
                    return [{ name: 'Permission denied', message: 'No discussion' }];
                }
                if (!discussion) {
                    const hasAddAttachmentPermit = this.hasCustomRolePermit(workspaceId || '', 'discussion.message.add.attachment');
                    return hasAddAttachmentPermit.length ? hasAddAttachmentPermit : [undefined, true];
                }
                const member = discussion?.members?.find(member => member?.id === 'user_' + this.userId);
                const [canAddAttachmentsError] = this.hasDiscussionPermit(discussion?.cid, 'discussion.message.add.attachment', member?.permissions);
                if (canAddAttachmentsError) {
                    return [canAddAttachmentsError];
                }

                return [undefined, true];
            }
        }
    }

    start = {
        private: (
            recipientId: string,
            recipientWorkspaces: { [workspaceId: string]: { _id: string, settings: { isolatedTeams?: boolean } } },
            recipientTeams: { cid: string, members?: string[], public?: boolean }[]
        ): PermissionCheckReturn => {
            let any: boolean | null = null;
            let publicTeam: boolean | null = null;
            const noCustomRole: { [workspaceId: string]: boolean } = {};

            for (const workspaceId of Object.keys(recipientWorkspaces)) {
                const [adminError, admin] = this.isAdmin(workspaceId);
                if (admin) {
                    return [undefined, true];
                }
            }

            if (!Object.keys(recipientWorkspaces || {}).length) {
                return [{ name: 'Permission denied', message: 'Recipient workspaces not found.' }];
            }

            for (const [workspaceId, workspace] of Object.entries(recipientWorkspaces)) {
                if (!this.permissions[workspaceId]) {
                    continue;
                }

                noCustomRole[workspaceId] = true;

                const [canStartAnyError, canStartAny] = this.hasCustomRolePermit(workspaceId, 'discussion.start.private.any');
                if (canStartAnyError) {
                    // No permission to start any private discussion in specified workspace
                    any = false;
                    noCustomRole[workspaceId] = false;
                }

                if (canStartAny) {
                    // Has permission to start private discussions in specified workspace
                    any = true;
                    noCustomRole[workspaceId] = false;
                    // Loop can be broken here since user has permission so no need to check other workspaces
                    break;
                }

                const [canStartPublicTeamError, canStartPublicTeam] = this.hasCustomRolePermit(workspaceId, 'discussion.start.private.publicTeam');
                if ((canStartPublicTeamError || canStartPublicTeam) && !workspace.settings.isolatedTeams) {
                    // No permission to start private discussions since user has custom role but isolated teams are off in this workspace and user does not have "any" permit
                    publicTeam = false;
                    noCustomRole[workspaceId] = false;
                    continue;
                }

                if (canStartPublicTeamError) {
                    // No permission to start any private discussion in specified workspace
                    publicTeam = false;
                    noCustomRole[workspaceId] = false;
                    continue;
                }

                if (canStartPublicTeam) {
                    noCustomRole[workspaceId] = false;
                    publicTeam = !!recipientTeams.find(team => (team.cid === workspaceId) && team.public && team.members?.includes(recipientId));
                    if (publicTeam) {
                        // Loop can be broken here since user has permission to start a private discussion
                        break;
                    }

                    continue;
                }

                if (noCustomRole) {
                    // Loop can be broken here since user does not have custom role in specified workspace so they have permission to start the discussion
                    break;
                }
            }

            if ((any === null && publicTeam === null) || Object.values(noCustomRole).includes(true)) {
                return [undefined, true];
            }

            if (any || publicTeam) {
                return [undefined, true];
            }

            if (!any && publicTeam) {
                return [{ name: 'Permission denied', message: 'User can only start discussion with members from public teams' }];
            }

            return [{ name: 'Permission denied', message: 'User cannot start private discussions' }];
        }
    }

    /** Checks if discussion has the correct permit key for the user. If not, then fallback to checking from role */
    private hasDiscussionPermit(workspaceId: string, permitKey: PermitKeys, discussionPermissions?: PermitKeys[]): PermissionCheckReturn {
        const [adminError, admin] = this.isAdmin(workspaceId);
        const [customRoleError, customRole] = this.hasCustomRolePermit(workspaceId, 'discussion.manage');
        const moderator = discussionPermissions?.includes('discussion.manage') || customRole;
        if (admin || moderator) {
            return [undefined, true];
        }

        const permit = discussionPermissions?.includes(permitKey);
        if (discussionPermissions?.length && !permit) {
            return [{ name: 'Permission denied', message: `User role does not have ${permitKey} permit in specific discussion` }];
        }

        const customPermit = this.hasCustomRolePermit(workspaceId, permitKey);
        if (customPermit.length) {
            return customPermit;
        }

        if (!discussionPermissions?.length && !customPermit.length) {
            return [];
        }

        if (!permit) {
            return [{ name: 'Permission denied', message: `User role does not have ${permitKey} permit in specific discussion` }];
        }

        return [undefined, true];
    }

    private workspacePermissions(workspaceId: string): [error?: PermissionError, permissions?: WorkspacePermissions] {
        if (!this.permissions) {
            return [{ name: 'Invalid permissions', message: 'User permissions are undefined', code: PermissionErrorCodes.missing, workspaceId }];
        }

        const workspacePermissions = this.permissions[workspaceId];
        if (!workspacePermissions) {
            return [{ name: 'Invalid permissions', message: 'Workspace permissions are undefined', code: PermissionErrorCodes.missing, workspaceId }];
        }

        return [undefined, workspacePermissions];
    }

    private hasCustomRolePermit(workspaceId: string, permitKey: PermitKeys): PermissionCheckReturn {
        const [adminError, admin] = this.isAdmin(workspaceId);
        if (admin) {
            return [undefined, true];
        }
        const [workspacePermissionError, workspacePermissions] = this.workspacePermissions(workspaceId);
        if (workspacePermissionError) {
            return [workspacePermissionError];
        }

        const customRole = workspacePermissions?.workspace.custom;
        if (!customRole) {
            return [];
        }

        if (!customRole.permits?.includes(permitKey)) {
            return [{ name: 'Permission denied', message: `User role does not have ${permitKey} permit in discussions` }];
        }

        return [undefined, true];
    }
}